import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
export class Login2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
