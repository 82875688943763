// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { RegisterService } from './register.service';
import Swal from 'sweetalert2';
import { Ang_App_Url } from '../../../../globals';
import { empty } from 'object-path';


@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];

	public inputRegisterUsername: any;
	public inputRegisterCompanyname: any;
	public inputRegisterContact: any;
	public inputRegisterEmail: any;
	public inputRegisterCountry: any;
	public inputRegisterState: any;
	public inputRegisterCity: any;
	public tandc: any;
	countryArray: any;
	stateArray: any;
	cityArray: any;
	submited: any = false;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	showMsg: boolean = false;
	class: any;
	msg: any;

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private registerService: RegisterService,
		private formBuilder: FormBuilder,
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.inputRegisterUsername = '';
		this.inputRegisterCompanyname = '';
		this.inputRegisterContact = '';
		this.inputRegisterEmail = '';
		this.inputRegisterCountry = '';
		this.inputRegisterState = '';
		this.inputRegisterCity = '';
		//this.initRegisterForm();
		this.getCountryArray();
		//this.getStateArray();
		//this.getCityArray();
		this.registerForm = this.formBuilder.group({
			inputRegisterUsername: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
			inputRegisterCompanyname: new FormControl('', [Validators.required]),
			// inputRegisterContact: new FormControl('',[Validators.required, Validators.pattern('[6-9]\\d{9}')]),
			inputRegisterContact: new FormControl('', [Validators.required]),
			inputRegisterEmail: new FormControl('', [Validators.required, Validators.email]),
			inputRegisterCountry: new FormControl('', [Validators.required]),
			inputRegisterState: new FormControl('', [Validators.required]),
			inputRegisterCity: new FormControl('', [Validators.required]),
			tandc: new FormControl(false, [Validators.requiredTrue])
		});
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	getCountryArray() {
		this.registerService.getCountryArray().subscribe(
			(resp) => {
				this.countryArray = resp.data;
			});
	}

	// getStateArray(){
	// 	this.registerService.getStateArray().subscribe(
	// 		(resp) => {
	// 		this.stateArray= resp.data;
	// 		});
	// 	}

	// getCityArray(){
	// 	this.registerService.getCityArray().subscribe(
	// 		(resp) => {
	// 		this.cityArray= resp.data;
	// 		});
	// 	}

	getStateArray(e) {
		this.registerService.getStateArray(e.target.value).subscribe(
			(resp) => {
				this.stateArray = resp.data;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getCityArray(e) {
		this.registerService.getCityArray(e.target.value).subscribe(
			(resp) => {
				this.cityArray = resp.data;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	onChangetandc(e){
		this.tandc = e.target.checked;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			userName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			]),
			],
			agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		this.submited = true;
		if(this.tandc == null || this.tandc == false){
			Swal.fire({
				position: 'center',
				//type: 'success',
				title: "Please accept terms and conditions",
				showConfirmButton: true,
				confirmButtonText: 'OK',
			})
		}
		else{
		this.registerService.registerUser(this.inputRegisterUsername, this.inputRegisterCompanyname, this.inputRegisterEmail, this.inputRegisterContact, this.inputRegisterCountry, this.inputRegisterState, this.inputRegisterCity, this.tandc).subscribe(
			(resp) => {
				Swal.fire({
					position: 'center',
					//type: 'success',
					title: resp.message,
					showConfirmButton: true,
					confirmButtonText: 'OK',
				})

				if (resp.status == 200) {
					this.router.navigate(['/auth/login/']);
				}

			}, (err) => {
				//console.log(err.statusText);
				this.showMsg = true;
				this.class = "alert-danger";
				if (err.statusText == 'Email Id has already been taken') {
					this.msg = 'Email Id has already been taken';
				} else {
					this.msg = 'All fields are mandatory';
				}
			}

		);
		}
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
