import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { App_Url } from '../../../../globals';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  controls: any;
	invalid: any;
  myItem: any;

  constructor(private http: HttpClient, public toastr: ToastrService) { }
  public forgotPassword(inputEmail): Observable<any> {
    //var data ={'EmailID' : inputUsername , 'Password' :inputPassword  };
    let formData: FormData = new FormData();
    formData.append('EmailId', inputEmail);
    return this.http.post(App_Url+'api/submitForgotPassword',formData);
   }

   public sendData(formData): Observable<any> {
    //var data ={'EmailID' : inputUsername , 'Password' :inputPassword  };
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url+'api/sendData',formData);
   }

   public sendDataSmtp(formData): Observable<any> {
    //var data ={'EmailID' : inputUsername , 'Password' :inputPassword  };
    formData.append('api_token', this.getApiToken());
    return this.http.post(App_Url+'api/sendDataSmtp',formData);
   }

   getApiToken() {
    this.myItem = JSON.parse(localStorage.getItem('loginData'));
    var api_token = this.myItem.token_key;
    return api_token;
}
}
