import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { App_Url } from '../../../../../globals';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService3 {
  public myItem:any;
  public key = 'loginData';

  constructor(private http: HttpClient) { }

   public getUserData(user_id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    formData.append('user_id',user_id);
    return this.http.post(App_Url+'api/getUserData',formData);
  }

  public resetPassword(user_id, oldPassword, newPassword, confirmPassword): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    formData.append('user_id',user_id);
    formData.append('oldPassword',oldPassword);
    formData.append('newPassword',newPassword);
    formData.append('confirmPassword',confirmPassword);
    return this.http.post(App_Url+'api/resetPassword',formData);
  }

  public getUserTokenData(user_id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('user_id',user_id);
    return this.http.post(App_Url+'api/getUserTokenData',formData);
  }

  public logOutUser(user_id): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('api_token',this.getApiToken());
    formData.append('user_id',user_id);
    return this.http.post(App_Url+'api/logOutUserData',formData);
  }

  getApiToken()
  {
    this.myItem = JSON.parse(localStorage.getItem(this.key));
    var api_token = this.myItem.token_key;
    return api_token ;
  }
}
