//
// ===== File globals.ts
//
'use strict';

export const sep = '/';


 export const App_Url: string="http://axis-india.democheck.in/axisindiaapi/";
 export const Ang_App_Url: string="http://axis-india.democheck.in/";

//  export const App_Url: string="http://elpssoftware.axis-india.com/axisindiaapi/";
//  export const Ang_App_Url: string="http://elpssoftware.axis-india.com/";

//export const App_Url: string="http://127.0.0.1:8000/";
// export const Ang_App_Url: string="http://localhost:4200/";



export const one = 1;
export const two = 2;
export const three = 3;
export const pie = 3.14;
export const fiveh = 500;
export const meterval = 3779;

//let separationHeight;
//let separationWidth;
//let separationDepth;
//let separationStructure;
