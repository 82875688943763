import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { App_Url } from '../../../../globals';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
	controls: any;
	invalid: any;

  constructor(private http: HttpClient, public toastr: ToastrService) { }

getCountryArray(): Observable<any> {
    let formData: FormData = new FormData();
    return this.http.post(App_Url+'api/getCountryArray',formData);
}

getStateArray(countryid): Observable<any> {
  let formData: FormData = new FormData();
  formData.append('countryid', countryid);
  return this.http.post(App_Url+'api/getStateArray',formData);
}

getCityArray(stateid): Observable<any> {
  let formData: FormData = new FormData();
  formData.append('stateid', stateid);
  return this.http.post(App_Url+'api/getCityArray',formData);
}

  public registerUser(inputRegisterUsername,inputRegisterCompanyname,inputRegisterEmail,inputRegisterContact,inputRegisterCountry,inputRegisterState,inputRegisterCity, tandc): Observable<any> {
    //var data ={'EmailID' : inputUsername , 'Password' :inputPassword  };
    let formData: FormData = new FormData();
    formData.append('Username', inputRegisterUsername);
    formData.append('Companyname', inputRegisterCompanyname);
    formData.append('EmailId', inputRegisterEmail);
    formData.append('Contact', inputRegisterContact);
    formData.append('Country', inputRegisterCountry);
    formData.append('State', inputRegisterState);
    formData.append('City', inputRegisterCity);
    formData.append('tandc', tandc);
    return this.http.post(App_Url+'api/submitRegisterUserData',formData);
   }


   AlreadyRegister() {
    this.toastr.error('', 'The Email Id already exists.', { "timeOut": 2000 });
  }
  
   typeSuccess() {
    this.toastr.success('Registration done Successfully!');
  }

  public getthunderstormdays(userobject): Observable<any>{
    let formData: FormData = new FormData();
    formData.append('userobject', userobject);
    return this.http.post(App_Url+'api/getthunderstormdays',formData);
  }
}
