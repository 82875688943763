// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	//
	// {path: 'auth', redirectTo: 'auth/login', pathMatch: 'full'},

	// // enable this router to set which demo theme to load,
	// {path: '', redirectTo: 'auth/login', pathMatch: 'full'},

	// {path: '**', redirectTo: 'error/403', pathMatch: 'full'},

	//
	{path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule)},

	// enable this router to set which demo theme to load,
	/** START: remove this themes list on production */
	{path: 'admin', redirectTo: 'admin', pathMatch: 'full'},
	{path: 'user', redirectTo: 'demo4', pathMatch: 'full'},
	// list of routers specified by demos, for demo purpose only!

	{path: 'admin', loadChildren: () => import('../app/views/themes/demo1/theme.module').then(m => m.ThemeModule)},
	{path: 'user', loadChildren: () => import('../app/views/themes/demo4/theme.module').then(m => m.ThemeModule)},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},

	//
	// {path: 'auth', redirectTo: 'auth/login', pathMatch: 'full'},

	// // enable this router to set which demo theme to load,
	// {path: '', loadChildren: () => import('app/views/themes/demo1/theme.module').then(m => m.ThemeModule)},

	// {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
