import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { App_Url } from '../../../../../globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Subheader4Service {
  public myItem:any;
  public myProject:any;
  public key = 'loginData';

  constructor(private http: HttpClient) { }

  public generatePDF(): Observable<any> {
    this.myProject = JSON.parse(localStorage.getItem('projectData'));
    let formData: FormData = new FormData();
    formData.append('projectId', this.myProject.id);
    formData.append('api_token',this.getApiToken());
    return this.http.post(App_Url+'api/generate-pdf',formData);
  }

  getApiToken()
  {
    this.myItem = JSON.parse(localStorage.getItem('loginData'));
    var api_token = this.myItem.token_key;
    return api_token;
  }
}
