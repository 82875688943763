// Angular
import { Component, Input, OnInit, ViewChild } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UserProfileService } from './user-profile.service';
import { NavigationEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	myForm: FormGroup;
	public submitted:boolean = false;
	public modalRef: any;
	@Input() avatar: boolean = true;
	@Input() greeting: boolean = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	public myItem:any;
	public myItemId:any;
	public key ='loginData';
	oldPassword:any;
	newPassword:any;
	cPassword:any;
	mySubscription: any;
	@ViewChild('resetPassword', { static: false }) private resetPassword;
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>, private modalService: NgbModal,private formBuilder:FormBuilder, private userProfileService: UserProfileService, private router: Router) {
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};

		this.mySubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
			// Trick the Router into believing it's last link wasn't previously loaded
			this.router.navigated = false;
			}
		});
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.myForm = this.formBuilder.group({
			'oldPassword': new FormControl('', [Validators.required]),
			'newPassword': new FormControl('', [Validators.required]),
			'cPassword': new FormControl('', [Validators.required])

		  },{validator: this.checkPasswords });

		this.user$ = this.store.pipe(select(currentUser));
		this.myItemId = JSON.parse(localStorage.getItem(this.key));
		this.getUserTokenData(this.myItemId.id);
		this.myItem = JSON.parse(localStorage.getItem('loginData'));
	}

	ngOnDestroy() {
		if (this.mySubscription) {
		  this.mySubscription.unsubscribe();
		}
	  }

	  public getUserTokenData(user_id) {
		this.userProfileService.getUserTokenData(user_id).subscribe(
		  (resp) => {
			if(resp.data.credential.token_key != this.myItem.token_key) {
				this.logout();
			}
		  },
		  (err) => {
			console.log(err);
		  }
		);
	  }

	/**
	 * Log out
	 */
	logout() {
		this.userProfileService.logOutUser(this.myItemId.id).subscribe(
			(resp) => {
			this.store.dispatch(new Logout());
			localStorage.removeItem('loginData');
			location.reload(true);
		},
			(err) => {
			console.log(err);
		}
	);
		// this.store.dispatch(new Logout());
		// localStorage.removeItem('loginData');
		// location.reload(true);
	}

	openResetPassword(resetPassword) {
		this.modalRef = this.modalService.open(this.resetPassword, { size: 'lg' });
	}

	submit() {
		this.submitted = true;
    // stop here if form is invalid
		if (this.myForm.invalid) {
			return;
		}
		this.userProfileService.resetPassword(this.myItemId.id,this.oldPassword,this.newPassword,this.cPassword).subscribe(
			(resp) => {
        if(resp.status == 200) {
			this.reset();
		  this.modalRef.dismiss();
          Swal.fire({
            position: 'center',
            //type: 'success',
            title: resp.message,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          })
        }
        if(resp.total_count!=0) {
          this.reset();

          //this.router.navigate(['/admin/group/']);
          }
      },
			(err) => {

        Swal.fire({
          position: 'center',
         // type: 'warning',
          title: err.error.errorMessage,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        })
			}
		);
  }

  public reset(){
    this.myForm.reset();
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
	let pass = group.get('newPassword').value;
	let confirmPass = group.get('cPassword').value;

	return pass === confirmPass ? null : { notSame: true }
	}
}
