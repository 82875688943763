import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { App_Url } from '../../../../globals';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, public toastr: ToastrService) { }

  public checkLogin(inputUsername,inputPassword): Observable<any> {
    //var data ={'EmailID' : inputUsername , 'Password' :inputPassword  };
    let formData: FormData = new FormData();
    formData.append('EmailID', inputUsername);
    formData.append('Password', inputPassword);
    return this.http.post(App_Url+'api/checkLoginData',formData);
   }

   InactiveUser() {
    this.toastr.error('', 'Your account is not active. Please contact admin.', { "timeOut": 2000 });
  }
  
   InvalidUser() {
    this.toastr.error('', 'Invalid Credentials!', { "timeOut": 2000 });
  }
  
   typeSuccess() {
    this.toastr.success('Login Successfully!');
  }
}
