// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
import { LoginService } from './login.service';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
    EMAIL: 'admin@demo.com',
    PASSWORD: 'demo'
};

@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    // Public params
    loginForm: FormGroup;
    loading = false;
    isLoggedIn$: Observable<boolean>;
    errors: any = [];
    userdata: any = [];

    public inputUsername: any;
    public inputPassword: any;
    public key: any;

    private unsubscribe: Subject<any>;

    private returnUrl: any;
    showMsg: boolean;
    class: string;
    msg: any;

    // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param router: Router
     * @param auth: AuthService
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     * @param route
     */
    constructor(
        private router: Router,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private loginService: LoginService
    ) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.userdata = JSON.parse(localStorage.getItem('loginData'));
        if (this.userdata != null) {
            if (this.userdata.role == 1)
                this.router.navigate(['/admin/dashboard']);
            else if (this.userdata.role == 2)
                this.router.navigate(['/user/dashboard']);
        }
        this.initLoginForm();        		// redirect back to the returnUrl before login

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initLoginForm() {
        // demo message to show
        // if (!this.authNoticeService.onNoticeChanged$.getValue()) {
        // 	const initialNotice = `Use account
        // 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
        // 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
        // 	this.authNoticeService.setNotice(initialNotice, 'info');
        // }

        this.loginForm = this.fb.group({
            email: [DEMO_PARAMS.EMAIL, Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
            ])
            ],
            password: [DEMO_PARAMS.PASSWORD, Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ]
        });
    }

    /**
     * Form Submit
     */
    submit() {
        this.loginService.checkLogin(this.inputUsername, this.inputPassword).subscribe(
            (resp) => {
                const controls = this.loginForm.controls;
                /** check form */
                if (this.loginForm.invalid) {
                    Object.keys(controls).forEach(controlName =>
                        controls[controlName].markAsTouched()
                    );
                    return;
                }

                this.loading = true;

                const authData = {
                    email: controls['email'].value,
                    password: controls['password'].value
                };
                if (resp.status == 200) {
                    //this.router.navigate(['/admin/dashboard/']);
                    this.auth
                        .login(authData.email, authData.password)
                        .pipe(
                            tap(user => {
                                if (resp.loginData.is_enabled == 1) {
                                    this.store.dispatch(new Login({ authToken: user.accessToken }));
                                    localStorage.setItem('loginData', JSON.stringify(resp.loginData));
                                    if (resp.loginData.role == "1") {
                                        this.router.navigate(['/admin/dashboard/']);
                                    } else {
                                        this.router.navigate(['/user/user-modules/']);
                                        // if (resp.total == 0) {
                                        //     this.router.navigate(['/user/add-project/']);
                                        // } else {
                                        //     this.router.navigate(['/user/dashboard/']);
                                        // }
                                    }
                                    //this.router.navigateByUrl(this.returnUrl); // Main page
                                } else {
                                    this.showMsg = true;
                                    this.class = "alert-danger";
                                    this.msg = resp.message;
                                }
                            }),
                            takeUntil(this.unsubscribe),
                            finalize(() => {
                                this.loading = false;
                                this.cdr.markForCheck();
                            })
                        )
                        .subscribe();
                }


            }, (err) => {
                this.showMsg = true;
                this.class = "alert-danger";
                if (err.error.message) {
                    this.msg = err.error.message;
                } else {
                    this.msg = 'Please enter username and password both';
                }
            }
        );
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
}
