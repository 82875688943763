import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-tm',
  templateUrl: './tm.component.html',
  styleUrls: ['./tm.component.scss']
})
export class TmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
